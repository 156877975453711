import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import LoadingIndicator from './components/LoadingIndicator';

const AuthHandler = ({ children }) => {
  const [user, setUser] = useState(null);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);

      if (user) {
        const scheduleExecutionJSON = localStorage.getItem('scheduleExecution');
        if (scheduleExecutionJSON) {
          const scheduleExecution = JSON.parse(scheduleExecutionJSON);
          navigate('/scheduleExecution', { state: { scheduleExecution } });
        }
        const ticketDetailsJSON = localStorage.getItem('ticket');
        if (ticketDetailsJSON) {
          const ticket = JSON.parse(ticketDetailsJSON);
          navigate(`/tickets/${ticket.id}`, { state: { ticket } });
        }
        const storedCode = localStorage.getItem('invitationCode');
        if (storedCode) {
          localStorage.removeItem('invitationCode');
          navigate(`/linkAssociate?code=${storedCode}`);
          localStorage.removeItem('invitationCode');
        }
      } else {
        // Remove cached schedule from localStorage
        localStorage.removeItem('scheduleExecution');
        localStorage.removeItem('ticket');

        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        if (code) {
          localStorage.setItem('invitationCode', code);
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return children(user);
};

export default AuthHandler;