import './LinkAssociateScreen.css';
import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { CompanyContext } from '../context/CompanyContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { callGetAssociateByCode, callGetAssociatedCompany, callLinkAssociate } from '../functions';
import NavBar from '../components/NavBar';
import Button from '../components/Button';
import LoadingIndicator from '../components/LoadingIndicator';

const LinkAssociate = () => {
  const { t } = useTranslation();
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [isAssociateLoading, setIsAssociateLoading] = useState(true);
  const [associate, setAssociate] = useState(null);
  const [loading, setLoading] = useState(false);

  const user = useContext(UserContext);
  const { updateUserCompanies } = useContext(CompanyContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const code = queryParams.get('code');

  const handleCancel = () => {
    navigate('/');
  };

  const handleLink = async () => {
    try {
      setLoading(true);
      const response = await callLinkAssociate(code);
      const data = response.data;
      if (data.success) {
        alert(t('linkAssociate.success'));
        await updateUserCompanies();
        navigate('/profile');
      } else {
        let errorMessage = "";
        switch (data.error) {
          case "emailNotUnique":
            errorMessage = t('linkAssociate.error.emailNotUnique');
            break;
          case "userIsOwner":
            errorMessage = t('linkAssociate.error.userIsOwner');
            break;
          case "codeUsed":
            errorMessage = t('linkAssociate.error.codeUsed');
            break;
        
          case "invalidCode":
            errorMessage = t('linkAssociate.error.invalidCode');
            break;
          default:
            errorMessage = t('linkAssociate.error.linking');
            break;
        }
        alert(errorMessage);
      }
    } catch {
      alert(t('linkAssociate.error.linking'));
    } finally {
      setLoading(false);
    }
    
  };

  useEffect(() => {
    const fetchAssociate = async () => {
      try {
        setIsAssociateLoading(true);
        const response = await callGetAssociateByCode(code);
        setAssociate(response);
      } catch {
        alert(t('linkAssociate.error.fetchAssociate'));
      } finally {
        setIsAssociateLoading(false);
      }
    }
    const fetchCompany = async () => {
      try {
        setIsCompanyLoading(true);
        const response = await callGetAssociatedCompany(code);
        setCompany(response);
      } catch {
        alert(t('linkAssociate.error.fetchCompany'));
        alert('Error fetching company'); // TODO: JD translation
      } finally {
        setIsCompanyLoading(false);
      }
    }

    if (user && code) {
      fetchAssociate();
      fetchCompany();
    }
  }, [user, code])

  return (
    <div className='linkAssociate-container'>
      <NavBar
        title={t("linkAssociate.title")}
        className="navbar"
        rightButtonTitle={t("general.cancel")}
        onRightButtonClick={handleCancel}
      />

      { loading ? (
        <LoadingIndicator />
      ) : (
        <>
        <h1>{t('linkAssociate.callToAction')}</h1>
      <div className="box">
        <h2>{t('linkAssociate.companyHeader')}</h2>
        {company ? (
          <div>
            <p>{company.name}</p>
          </div>
        ) :
          isCompanyLoading ? (
            <LoadingIndicator />
          ) :
            (
              <p>{t('linkAssociate.noCompanyData')}</p>
            )}
      </div>
      <div className="box">
        <h2>{t('linkAssociate.associateHeader')}</h2>
        {associate ? (
          <div>
            <p>{associate.name}</p>
          </div>
        ) :
          isAssociateLoading ? (
            <LoadingIndicator />
          ) :
            (
              <p>{t('linkAssociate.noAssociateData')}</p>
            )}
      </div>
      <div className="box">
        <h2>{t('linkAssociate.userHeader')}</h2>
        {user ? (
          <div>
            <p>{user.name}</p>
            <p>{user.email}</p>
          </div>
        ) : (
          <p>{t('linkAssociate.noUserData')}</p>
        )}
      </div>
      <Button
        id="link-button"
        variant="primary"
        onClick={handleLink}
        disabled={!company || !associate || !user}
      >
        {t('linkAssociate.linkButton')}
      </Button>
      </>
      )
    }
    </div>
  );
};

export default LinkAssociate;