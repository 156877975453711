import React, { createContext, useState, useEffect, useContext } from 'react';
import { callGetDepartments } from '../functions';
import { CompanyContext } from './CompanyContext'

export const DeparmentContext = createContext();

export const DepartmentProvider = ({ children }) => {
  const [departments, setDepartments] = useState([]);
  const { selectedCompany } = useContext(CompanyContext);

  useEffect(() => {
    const fetchProperties = async () => {
      if (selectedCompany) {
        try {
          const departmentList = await callGetDepartments(selectedCompany.id);
          setDepartments(departmentList);
        } catch (error) {
          console.error('Error fetching departments:', error);
        }
      } else {
        setDepartments([]);
      }
    };

    fetchProperties();
  }, [selectedCompany]);

  return (
    <DeparmentContext.Provider value={{ departments }}>
      {children}
    </DeparmentContext.Provider>
  );
};