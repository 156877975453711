import React, { useEffect, useCallback, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NavBar from '../components/NavBar';
import SegmentedControl from '../components/SegmentedControl';
import RowItem from '../components/RowItem';
import { CompanyContext } from '../context/CompanyContext';
import { PropertyContext } from '../context/PropertyContext';
import { UserContext } from '../context/UserContext';
import { callGetTicket, callUpdateTicket, callCreateTicket, callDeleteGalleryAsset, callCreateGalleryAsset } from '../functions';
import { logIssueReport, logIssueEdit } from '../analytics';
import LoadingIndicator from '../components/LoadingIndicator';
import useTicketStatusParser from '../utils/ticketUtils';
import './TicketDetailsScreen.css';
import { Box, TextField, Dialog, DialogTitle, List, ListItem, ListItemText, DialogContent, DialogActions, Button, Typography, Grid, Card, CardMedia } from '@mui/material';
import { FirebaseError } from 'firebase/app';

const TicketDetailsScreen = () => {
  const { selectedCompany } = useContext(CompanyContext);
  const { properties } = useContext(PropertyContext);
  const user = useContext(UserContext);
  const [ticketId, setTicketId] = useState(useParams().ticketId);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState(location.state?.ticket);
  const [isLoading, setIsLoading] = useState(false);
  const { parseTicketStatus, parseTicketPriority, getPriorityColor, parseTicketType } = useTicketStatusParser();
  const [status, setStatus] = useState(ticket?.status || 'OPEN');
  const [priority, setPriority] = useState(ticket?.priority || 'Low');
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [notes, setNotes] = useState(ticket?.notes || '');
  const [selectedProperty, setSelectedProperty] = useState(ticket?.property);
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const [propertyErrorMessage, setPropertyErrorMessage] = useState('');
  const [titleErrorMessage, setTitleErrorMessage] = useState('');
  const [gallery, setGallery] = useState(ticket?.gallery?.map(item => (
    {
      state: "existing",
      asset: item
    })) || []);
  const [galleryModalItem, setGalleryModalItem] = useState(null);

  const MAX_NOTES_LENGTH = 250;

  const isNewTicket = ticketId === 'new';

  useEffect(() => {
    if (!selectedCompany) { return }

    if (!ticketId) {
      navigate('/tickets');
    }

    const fetchTicket = async () => {
      setIsLoading(true);
      try {
        const fetchedTicket = await callGetTicket(ticketId);
        setTicket(fetchedTicket);
      } catch (error) {
        console.error('Error fetching ticket:', error);
        localStorage.removeItem('ticket');
        setTicket(null);
        setTicketId(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (ticketId !== ticket?.id) {
      if (isNewTicket) {
        setTicket({
          id: 'new',
          ticketNumber: 'tba',
          status: 'OPEN',
          priority: 'Low',
          companyId: selectedCompany.id,
          date: Math.floor(Date.now() / 1000),
          reportedBy: {
            id: selectedCompany.associate.id,
            name: selectedCompany.associate.name
          }
        })
      } else {
        fetchTicket();
      }
    }
  }, [ticket, navigate, ticketId, selectedCompany]);

  useEffect(() => {
    if (ticket) {
      localStorage.setItem('ticket', JSON.stringify(ticket));
    }
  }, [ticket]);


  const handleBack = () => {
    localStorage.removeItem('ticket');
    setTicket(null);
    setTicketId(null);
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    const newStatusTicket = { ...ticket, status: newStatus };
    setTicket(newStatusTicket);
  };

  const handlePriorityChange = (newPriority) => {
    setPriority(newPriority);
    const newTicket = { ...ticket, priority: newPriority };
    setTicket(newTicket);
  };

  const handleSave = async () => {
    if (!selectedProperty) {
      setPropertyErrorMessage(t('error.propertyRequired'));
    } else {
      setPropertyErrorMessage('');
    }

    if (!ticket.title) {
      setTitleErrorMessage(t('error.ticketNameRequired'));
    } else {
      setTitleErrorMessage('');
    }

    if (!ticket.title || !selectedProperty) {
      return;
    }

    setIsLoading(true);
    const apiFunction = isNewTicket ? callCreateTicket : callUpdateTicket;

    try {
      const updateTicket = await apiFunction(ticket, ticket.property.id);
      if (isNewTicket) {
        logIssueReport(selectedCompany.id, selectedProperty.id, ticket.title);
      } else {
        logIssueEdit(selectedCompany.id, selectedProperty.id, ticket.title);
      }

      let hadErrors = false;

      let lastGalleryAssets = updateTicket.gallery
      for (const item of gallery) {
        if (item.state === 'deleted') {
          const asset = item.asset;
          try {
            await callDeleteGalleryAsset(updateTicket.id, asset.id);
          } catch (error) {
            console.error('Error deleting gallery asset:', error);
            hadErrors = true;
          }
        }

        if (item.state === 'new') {
          const asset = item.asset;
          try {
            lastGalleryAssets = await callCreateGalleryAsset(user.id, updateTicket.id, asset);
          } catch (error) {
            console.error('Error creating gallery asset:', error);
            hadErrors = true;
          }
        }
      };
      
      setIsLoading(false);
      if (hadErrors) {
        alert(t("error.savedWithErrors"));
        setTicket(null);
        setTicketId(null);
        return;
      }
      if(lastGalleryAssets) {
        updateTicket.gallery = lastGalleryAssets;  
      } else {
        delete updateTicket.gallery;
      }
      setTicket(updateTicket);
      setTicketId(updateTicket.id);
      alert(t('ticketDetails.saved'));
    } catch (error) {
      if(error instanceof FirebaseError) {
        const message = error.details?.error?.message;
        //TODO: JD Translation
        alert(message);
      } else {
        alert(t("error.general"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onTicketTypeClick = () => {
    setIsTypeModalOpen(true);
  }

  const handleTypeSelect = (type) => {
    setTicket({ ...ticket, type });
    setIsTypeModalOpen(false);
  };

  const onNotesClick = () => {
    setIsNotesModalOpen(true);
  };

  const handleNotesSave = () => {
    setTicket({ ...ticket, notes });
    setIsNotesModalOpen(false);
  };

  const handlePropertySelect = (property) => {
    const propertyHeader = property ? {
      id: property.id,
      name: property.name
    } : null;

    setSelectedProperty(propertyHeader);

    setPropertyErrorMessage('');
    setTicket({ ...ticket, property: propertyHeader });
    setIsPropertyModalOpen(false);
  };

  const handleGalleryAssetClick = (item) => {
    setGalleryModalItem(item);
  };

  const resizeImage = (img, maxDimension) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let width = img.width;
    let height = img.height;
  
    if (width > height) {
      if (width > maxDimension) {
        height = Math.round((height *= maxDimension / width));
        width = maxDimension;
      }
    } else {
      if (height > maxDimension) {
        width = Math.round((width *= maxDimension / height));
        height = maxDimension;
      }
    }
  
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
  
    return canvas.toDataURL('image/jpeg');
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const thumbnailUrl = resizeImage(img, 200);
          const assetUrl = resizeImage(img, 1000);

          const newImage = {
            asset: {
              type: 'photo',
              thumbnailUrl: thumbnailUrl,
              assetUrl: assetUrl
            },
            state: 'new'
          };
          console.log('newImage', newImage);
          setGallery((prevGallery) => [...prevGallery, newImage]);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      console.error('The selected file is not an image.');
      //TODO: JD error handling
    }
  };

  const statusOptions = ['OPEN', 'IN PROGRESS', 'CLOSED', 'ON HOLD'];
  const statusDisplayOptions = statusOptions.map(parseTicketStatus);
  const priorityOptions = ['Low', 'Medium', 'High', 'CRITICAL'];
  const priorityDisplayOptions = priorityOptions.map(parseTicketPriority);

  const typeOptions = ["issue", "project", "hoRequest", "internal", "other"];

  const selectedPriorityColor = getPriorityColor(priority);

  return (
    <div className="ticket-details-container">
      <NavBar
        title={"#" + ticket?.ticketNumber}
        onBackClick={handleBack}
        rightButtonTitle={t('general.save')}
        onRightButtonClick={handleSave}
      />
      {isLoading ? (
        <div className="loading-modal">
          <LoadingIndicator />
        </div>
      ) : (
        ticket && (
          <div className="ticket-details-content">
            <SegmentedControl
              rawValues={statusOptions}
              displayValues={statusDisplayOptions}
              onTabChange={handleStatusChange}
              selectedTab={status}
            />
            <div className="ticket-details-field">
              <TextField
                label={t('ticketDetails.name')}
                variant="outlined"
                fullWidth
                value={ticket.title}
                onChange={(e) => {
                  setTitleErrorMessage('');
                  setTicket({ ...ticket, title: e.target.value })
                }}
                InputProps={{
                  classes: {
                    root: 'text-field-background', // Apply the custom CSS class
                  },
                }}
              />
              {titleErrorMessage && <Typography variant='caption' color="error">{titleErrorMessage}</Typography>}
            </div>
            <div className="ticket-details-field">
              <TextField
                label={t('ticketDetails.description')}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={ticket.description}
                onChange={(e) => setTicket({ ...ticket, description: e.target.value })}
                InputProps={{
                  classes: {
                    root: 'text-field-background', // Apply the custom CSS class
                  },
                }}
              />
            </div>
            <RowItem
              title={t('ticketDetails.ticketNumber')}
              secondaryTitle=""
              details={ticket.ticketNumber}
              secondaryDetails=""
            />
            <SegmentedControl
              rawValues={priorityOptions}
              displayValues={priorityDisplayOptions}
              onTabChange={handlePriorityChange}
              selectedTab={priority}
              selectedColor={selectedPriorityColor}
            />
            <RowItem
              title={t('ticketDetails.property')}
              secondaryTitle=""
              details={ticket.property?.name}
              secondaryDetails=""
              showDetailsIndicator={isNewTicket}
              onClick={
                isNewTicket ? () => setIsPropertyModalOpen(true) : undefined
              }
            />
            {propertyErrorMessage && <Typography variant='caption' color="error">{propertyErrorMessage}</Typography>}
            <RowItem
              title={t('ticketDetails.department')}
              secondaryTitle=""
              details={ticket.department?.name}
              secondaryDetails=""
            />
            <RowItem
              title={t('ticketDetails.assignedTo')}
              secondaryTitle=""
              details={ticket.assignee?.name}
              secondaryDetails=""
            />
            <RowItem
              title={t('ticketDetails.type')}
              secondaryTitle=""
              details={ticket.type ? parseTicketType(ticket.type) : ''}
              secondaryDetails=""
              showDetailsIndicator={true}
              onClick={onTicketTypeClick}
            />
            <RowItem
              title={t('ticketDetails.notes')}
              secondaryTitle=""
              details=""
              secondaryDetails=""
              showDetailsIndicator={true}
              onClick={onNotesClick}
            />
            <RowItem
              title={t('ticketDetails.reportedBy')}
              secondaryTitle=""
              details={ticket.reportedBy?.name}
              secondaryDetails=""
            />
            <RowItem
              title={t('ticketDetails.date')}
              secondaryTitle=""
              details={new Date(ticket.date * 1000).toLocaleDateString()}
              secondaryDetails=""
            />
              <div className="gallery-container">
              <Grid container spacing={4} sx={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
                  {gallery
                    .filter(item => item.asset.type === 'photo')
                    .filter(item => item.state !== 'deleted')
                    .map((item, index) => (
                      <Grid item key={index} sx={{ flex: '0 0 auto' }}>
                        <Card
                          onClick={() => handleGalleryAssetClick(item)}
                          sx={{
                            width: 50,
                            height: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={item.asset.thumbnailUrl}
                            alt={`Gallery item ${index + 1}`}
                            title={`Gallery item ${index + 1}`}
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain'
                            }}
                          />
                        </Card>
                      </Grid>
                    ))}
                  <Grid item sx={{ flex: '0 0 auto' }}>
                    <Card
                      sx={{
                        width: 50,
                        height: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                      onClick={() => document.getElementById('file-input').click()}
                    >
                      <Typography variant="h4" color="primary">+</Typography>
                      <input
                        type="file"
                        id="file-input"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </div>
          </div>
        )
      )}
      <Dialog open={isTypeModalOpen} onClose={() => setIsTypeModalOpen(false)}>
        <DialogTitle>{t('ticketDetails.selectType')}</DialogTitle>
        <List>
          {typeOptions.map((type) => (
            <ListItem button onClick={() => handleTypeSelect(type)} key={type}>
              <ListItemText primary={parseTicketType(type)} />
            </ListItem>
          ))}
        </List>
      </Dialog>

      <Dialog open={isNotesModalOpen} onClose={() => setIsNotesModalOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>{t('ticketDetails.editNotes')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('ticketDetails.notes')}
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={notes}
            onChange={(e) => setNotes(e.target.value.slice(0, MAX_NOTES_LENGTH))} // Limit the length of notes
            helperText={`${notes.length}/${MAX_NOTES_LENGTH}`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNotesModalOpen(false)} color="primary">
            {t('general.cancel')}
          </Button>
          <Button onClick={handleNotesSave} color="primary">
            {t('general.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isPropertyModalOpen} onClose={() => setIsPropertyModalOpen(false)}>
        <DialogTitle>{t('ticketDetails.selectProperty')}</DialogTitle>
        <DialogContent>
          <List>
            {properties.map((property) => (
              <ListItem button key={property.id} onClick={() => handlePropertySelect(property)}>
                <ListItemText primary={property.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPropertyModalOpen(false)}>{t('general.cancel')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={galleryModalItem !== null}
        onClose={() => setGalleryModalItem(null)}
      >
        <DialogTitle>{t('galleryModal.title')}</DialogTitle>
        <DialogActions>
          <Box display="flex" flexDirection="column" width="100%">
            <Button onClick={() => {
              window.open(galleryModalItem.asset.assetUrl, '_blank');
              setGalleryModalItem(null);
            }}>
              {t('galleryModal.view')}
            </Button>
            <Button onClick={() => {
              if (galleryModalItem.state === 'existing') {
                setGallery(gallery.map(item => item === galleryModalItem ? { ...item, state: 'deleted' } : item));
              } else {
                setGallery(gallery.filter(item => item !== galleryModalItem));
              }
              setGalleryModalItem(null);
            }} color="secondary">
              {t('galleryModal.delete')}
            </Button>
            <Button onClick={() => setGalleryModalItem(null)} color="primary">
              {t('galleryModal.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default TicketDetailsScreen;