import React,  { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';
import { DeparmentContext } from '../context/DepartmentContext';
import { PropertyContext } from '../context/PropertyContext';
import { AiOutlineClose } from 'react-icons/ai';
import "./TicketsScreenModal.css";
import RowItem from '../components/RowItem';
import { Dialog, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import useTicketStatusParser from '../utils/ticketUtils';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';

const TicketsScreenModal = ({ isOpen, onRequestClose }) => {

  const { t } = useTranslation();
  const { parseTicketStatus } = useTicketStatusParser();
  const { departments } = useContext(DeparmentContext);
  const { properties } = useContext(PropertyContext);

  const [isPropertyDialogOpen, setIsPropertyDialogOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [isDateRangeDialogOpen, setIsDateRangeDialogOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(30);

  const [isDepartmentsDialogOpen, setIsDepartmentsDialogOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [isStatusesDialogOpen, setIsStatusesDialogOpen] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState(['OPEN', 'IN PROGRESS', 'CLOSED', 'ON HOLD']);

  useEffect(() => {
    getFilterFromStorage();
  }, []);

  const handlePropertyClick = () => {
    setIsPropertyDialogOpen(true);
  };

  const handlePropertySelect = (property) => {
    setSelectedProperty(property);
    setIsPropertyDialogOpen(false);
    saveFilterInLocalStorage({
      property: property
    });
  };

  const handleDateRangeClick = () => {
    setIsDateRangeDialogOpen(true);
  };

  const handleDateRangeSelect = (range) => {
    setSelectedDateRange(range);
    setIsDateRangeDialogOpen(false);
    saveFilterInLocalStorage({
      dateRange: range
    });
  };

  const handleDepartmentsClick = () => {
    setIsDepartmentsDialogOpen(true);
  }

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setIsDepartmentsDialogOpen(false);
    saveFilterInLocalStorage({
      department: department
    });
  }

  const handleStatusesClick = () => {
    setIsStatusesDialogOpen(true);
  }

  const handleStatusSelect = (status) => {
    const currentStatuses = [...selectedStatuses];
    const index = currentStatuses.indexOf(status);
    if (index > -1) {
      currentStatuses.splice(index, 1);
    } else {
      currentStatuses.push(status);
    }
    setSelectedStatuses(currentStatuses);
    saveFilterInLocalStorage({
      statuses: currentStatuses
    });
  }

  const handleResetClick = () => {
    setSelectedDateRange(30);
    setSelectedDepartment(null);
    setSelectedStatuses(['OPEN', 'IN PROGRESS', 'CLOSED', 'ON HOLD']);
    setSelectedProperty(null);
    saveFilterInLocalStorage({
      dateRange: 30,
      department: null,
      statuses: ['OPEN', 'IN PROGRESS', 'CLOSED', 'ON HOLD'],
      property: null
    });
  }


  const saveFilterInLocalStorage = (params) => {
    localStorage.setItem('ticketFilter', JSON.stringify({
      dateRange: params.dateRange === undefined ? selectedDateRange : params.dateRange,
      department: params.department === undefined ? selectedDepartment : params.department,
      statuses: params.statuses === undefined ? selectedStatuses : params.statuses,
      property: params.property === undefined ? selectedProperty : params.property
    }));
  }
  
  const getFilterFromStorage = () => {
    const filter = localStorage.getItem('ticketFilter') || "{}";
    const { dateRange, department, statuses, property } = JSON.parse(filter);
    setSelectedDateRange(dateRange || 30);
    setSelectedDepartment(department || null);
    setSelectedStatuses(statuses || ['OPEN', 'IN PROGRESS', 'CLOSED', 'ON HOLD']);
    setSelectedProperty(property || null);
  }
  
  const handleShowClick = () => {
    onRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Filters Modal">
      <div className="modal-header">
        <h3>{t("ticketFilter.title")}</h3>
        <button className="close-button" onClick={onRequestClose}>
          <AiOutlineClose size={24} />
        </button>
      </div>
      <div
        className='modal-content'>
      <RowItem 
        title={t("ticketFilter.time")}
        details={t("ticketFilter.days", {count: selectedDateRange})}
        onClick={handleDateRangeClick} 
        showDetailsIndicator={true}/>

      <RowItem
        title={t("ticketFilter.department")}
        details={selectedDepartment ? selectedDepartment.name : t('general.all')}
        onClick={handleDepartmentsClick}
        showDetailsIndicator={true}/>

      <RowItem  
        title={t("ticketFilter.property")}
        details={selectedProperty ? selectedProperty.name : t('general.all')}
        onClick={handlePropertyClick}
        showDetailsIndicator={true}/>

      <RowItem
        title={t("ticketFilter.statuses")}
        details={
          selectedStatuses.length > 0 ? 
              selectedStatuses.map(parseTicketStatus).join(', ') : 
              t("general.all")
        }
        onClick={handleStatusesClick}
        showDetailsIndicator={true}/>

        <Button id="reset-button" variant="secondary" onClick={handleResetClick}>
          {t('ticketFilter.reset')}
        </Button>
        <Button id="show-button" variant="primary" onClick={handleShowClick}>
          {t('ticketFilter.show')}
        </Button>
      </div>
      <Dialog open={isPropertyDialogOpen} onClose={() => setIsPropertyDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>{t("ticketFilter.selectProperty")}</DialogTitle>
        <List>
          <ListItem button key="all" onClick={() => handlePropertySelect(null)}>
            <ListItemText primary={t("general.all")} />
          </ListItem>
          {
            properties.map((property) => (
              <ListItem button key={property.id} onClick={() => handlePropertySelect(property)}>
                <ListItemText primary={property.name} />
              </ListItem>
            ))
          }
        </List>
      </Dialog>
      <Dialog open={isStatusesDialogOpen} onClose={() => setIsStatusesDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>{t("ticketFilter.selectStatuses")}</DialogTitle>
        <List>
          {['OPEN', 'IN PROGRESS', 'CLOSED', 'ON HOLD'].map((status) => (
            <ListItem button key={status} onClick={() => handleStatusSelect(status)}>
              <ListItemText primary={parseTicketStatus(status)} />
              <div className="status-checkbox">
                {selectedStatuses.includes(status) && '✓'}
              </div>
            </ListItem>
          ))}
        </List>
      </Dialog>
      <Dialog open={isDepartmentsDialogOpen} onClose={() => setIsDepartmentsDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>{t("ticketFilter.selectDepartment")}</DialogTitle>
        <List>
          <ListItem button key="all" onClick={() => handleDepartmentSelect(null)}>
            <ListItemText primary={t("general.all")} />
          </ListItem>
          {
            departments.map((department) => (
              <ListItem button key={department} onClick={() => handleDepartmentSelect(department)}>
                <ListItemText primary={department.name} />
              </ListItem>
            ))
          }
        </List>
      </Dialog>
      <Dialog open={isDateRangeDialogOpen}  onClose={() => setIsDateRangeDialogOpen(false)} maxWidth="lg" fullWidth>
          <DialogTitle>{t("ticketFilter.selectTime")}</DialogTitle>
          <List>
            {
              [1, 3, 5, 7, 30].map((range) => (
                <ListItem button key={range} onClick={() => handleDateRangeSelect(range)}>
                  <ListItemText primary={ t("ticketFilter.days", {count: range}) } />
                </ListItem>
              ))
            }
          </List>
      </Dialog>
    </Modal>
  );
};

export default TicketsScreenModal;