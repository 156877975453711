import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes } from "react-router-dom";
import './MyDayScreen.css';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import SegmentedControl from '../components/SegmentedControl';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../context/CompanyContext';
import { callGetPlannedSchedules, callGetScheduleSummaries } from '../functions';
import RowItem from '../components/RowItem';
import ScheduleExecutionScreen from "../screens/ScheduleExecutionScreen";
import LoadingIndicator from '../components/LoadingIndicator';

const MyDayScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedCompany } = useContext(CompanyContext);
  const [selectedTab, setSelectedTab] = useState(t("myDay.today"));
  const [schedules, setSchedules] = useState([]);
  const [scheduleSummaries, setScheduleSummaries] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    const fetchSchedules = async () => {
      if (!selectedCompany) return;
      if (!selectedTab) return;

      const getStartAndEndTime = (day) => {
        const now = new Date();
        let startTime, endTime;
    
        switch (day) {
          case t("myDay.yesterday"):
            startTime = new Date(now.setDate(now.getDate() - 1)).setHours(0, 0, 0, 0) / 1000;
            endTime = startTime + 86400;
            break;
          case t("myDay.today"):
            startTime = new Date().setHours(0, 0, 0, 0) / 1000;
            endTime = new Date().setHours(23, 59, 59, 999) / 1000;
            break;
          case t("myDay.tomorrow"):
            startTime = new Date(now.setDate(now.getDate() + 1)).setHours(0, 0, 0, 0) / 1000;
            endTime = startTime + 86400;
            break;
          default:
            startTime = endTime = 0;
        }
        return { startTime, endTime };
      };

      setLoading(true);
      const { startTime, endTime } = getStartAndEndTime(selectedTab);
      const [plannedSchedules, summaries] = await Promise.all([
        callGetPlannedSchedules(selectedCompany.id, startTime, endTime),
        callGetScheduleSummaries(selectedCompany.id, startTime, endTime)
      ]);

      setSchedules(plannedSchedules);
      setScheduleSummaries(summaries);
      setLoading(false);
    };

    fetchSchedules();
  }, [selectedTab, selectedCompany, t]);

  const isScheduleCompleted = (schedule) => {
    return scheduleSummaries.some(summary => summary.schedule.id === schedule.schedule.id);
  };


  const renderScheduleItem = (schedule) => (
    <RowItem
      key={schedule.id}
      title={schedule.schedule.name}
      secondaryTitle={schedule.schedule.department.name}
      details={schedule.property.name}
      // display time of day only
      secondaryDetails={new Date(schedule.startDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      // secondaryDetails={new Date(schedule.startDate * 1000).toLocaleString()}
      image={isScheduleCompleted(schedule) ? '/images/scheduleComplete.png' : '/images/scheduleIncomplete.png'}
      onClick={() => {
        if (!isScheduleCompleted(schedule)) {
          navigate('/scheduleExecution', {
            state: {
              scheduleExecution: {
                schedule: schedule.schedule,
                property: schedule.property,
                page: 'start'
              }
            }
          });
        }
      }}
    />
  );

  return (
    <div className="myDay-container">
      <Routes>
      <Route path="/scheduleExecution" element={<ScheduleExecutionScreen />} />
      </Routes>
      <NavBar title={t("myDay.title")} className="navbar" />
      <SegmentedControl
        rawValues = {[t("myDay.yesterday"), t("myDay.today"), t("myDay.tomorrow")]}
        displayValues = {[t("myDay.yesterday"), t("myDay.today"), t("myDay.tomorrow")]}
        selectedTab = {selectedTab}
        onTabChange={handleTabChange}
      />
      <div className="myDay-content">
        {loading ? (
          <LoadingIndicator />
        ) : schedules.length > 0 ? (
          <div className="schedule-list">
            {schedules.map(renderScheduleItem)}
          </div>
        ) : (
          <div className="no-schedules">{t("myDay.noSchedules")}</div>
        )}
      </div>
    </div>
  );
};

export default MyDayScreen;