// src/screens/TicketsScreen.js
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import './TicketsScreen.css';
import { callGetTickets } from '../functions';
import LoadingIndicator from '../components/LoadingIndicator';
import RowItem from '../components/RowItem';
import { CompanyContext } from '../context/CompanyContext';
import useTicketStatusParser from '../utils/ticketUtils';
import { Fab } from '@mui/material';
import { FaPlus } from 'react-icons/fa';
import SearchBar from '../components/SearchBar';
import TicketsScreenModal from '../modals/TicketsScreenModal';

const TicketsScreen = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [allTicketsForDateRange, setAllTicketsForDateRange] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedCompany } = useContext(CompanyContext);
  const { parseTicketStatus, getPriorityFileSrc } = useTicketStatusParser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const fetchTickets = async () => {
    const getFilterFromStorage = () => {
      const filter = localStorage.getItem('ticketFilter') || "{}";
      const { dateRange, department, statuses, property } = JSON.parse(filter);
      return {
        dateRange: dateRange || 30,
        department: department || null,
        statuses: statuses || ['OPEN', 'IN PROGRESS', 'CLOSED', 'ON HOLD'],
        property: property || null
      };
    };
    setLoading(true);
    try {
      const filter = getFilterFromStorage();
      console.log('filter', filter);
      const oneWeekInSeconds = filter.dateRange * 24 * 60 * 60;
      const endTime = Math.floor(Date.now() / 1000);
      const startTime = endTime - oneWeekInSeconds;
      const response = 
        dateRange === filter.dateRange ?
        allTicketsForDateRange :
        await callGetTickets(selectedCompany.id, startTime, endTime);

      setAllTicketsForDateRange(response);
      setDateRange(filter.dateRange);

      const filtered = response.filter((ticket) => {
        if (filter.property) {
          if (ticket.property?.id !== filter.property.id) {
            return false;
          }
        }
        if (filter.department) {
          if (ticket.department?.id !== filter.department.id) {
            return false;
          }
        }
        if (filter.statuses.length > 0) {
          if (!filter.statuses.includes(ticket.status)) {
            return false;
          }
        }

        return true;
      })

      filtered.sort((a, b) => b.ticketNumber - a.ticketNumber);
      setTickets(filtered);
    } catch(error) {
      console.log(error);
      alert('Error fetching tickets'); // TODO: JD translation
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedCompany) return;
    fetchTickets();
  }, [selectedCompany]);

  const filteredTickets = tickets.filter(ticket =>
    ticket.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleTicketClick = (ticket) => {
    navigate(`/tickets/${ticket.id}`, { state: { ticket } });
  };

  const handleAddTicket = () => {
    navigate('/tickets/new');
  };

  const handleFiltersClick = () => {
    setIsModalOpen(true);
  };

  const handleFilterClose = () => {
    setIsModalOpen(false);
    fetchTickets();
  }

  return (
    <div className="tickets-container">
      <NavBar title={t("tickets.title")} className="navbar" rightButtonTitle={t("tickets.filters")} onRightButtonClick={handleFiltersClick} />
      <SearchBar
        placeholder={t('scheduleExecution.areaList.search')}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
      />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="tickets-list">
          {filteredTickets.map(ticket => (
            <RowItem
              key={ticket.id}
              title={ticket.ticketNumber + " - " + ticket.title}
              secondaryTitle={ticket.property.name}
              details={new Date(ticket.date * 1000).toLocaleString()}
              secondaryDetails={parseTicketStatus(ticket.status)}
              image={getPriorityFileSrc(ticket.priority)}
              onClick={() => handleTicketClick(ticket)}
            />
          ))}
        </div>
      )}
      <TicketsScreenModal isOpen={isModalOpen} onRequestClose={() => {handleFilterClose()}} />
      {!isModalOpen &&
        <Fab
          color="primary"
          aria-label="add"
          className="fab"
          onClick={handleAddTicket}
        >
          <FaPlus />
        </Fab>
      }
    </div>
  );
};

export default TicketsScreen;